import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import logo from '../assets/logo.jpeg';

const Footer = () => {
  return (
    <footer className="bg-gray-600 text-white py-8 px-0 xl:px-16">
      <div className="max-w-screen-xl mx-auto px-6 sm:px-8 lg:px-8 xl:px-0 grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-16">
        <div>
          <div className="flex items-center">
            <img
              src={logo}
              alt="Brand Logo"
              width={60}
              height={60}
              className="bg-gray-200/15"
            />
            <h1 className="ml-4 text-xl font-bold font-ubuntu">STEM Avishkar Private Limited</h1>
          </div>
          <p className="mt-4 text-sm font-poppins font-light leading-relaxed">
          At STEM Avishkar Pvt Ltd, we revolutionize education by integrating cutting-edge technology with hands-on innovation. Our programs in Robotics, AI, IoT, Drone Technology, AR, VR, and 3D Printing inspire creativity and critical thinking in K-12 students.
          </p>
        </div>

        <div className="bg-gray-700 p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-bold font-poppins mb-4 text-center">
            Contact Us
          </h3>
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <FaPhoneAlt className="text-white text-xl" />
              <span className="font-poppins font-light text-sm tracking-wide">
                +91 - 9036041555 <br /> +91 - 9731280666
              </span>
            </div>
            <div className="flex items-center gap-4">
              <FaEnvelope className="text-white text-xl" />
              <span className="font-poppins font-light text-sm">stemavishkar@gmail.com</span>
            </div>
            <div className="flex items-center gap-4">
              <FaMapMarkerAlt className="text-white text-xl" />
              <span className="font-poppins font-light text-sm">Lakshmipuram, Mysuru</span>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-xl font-bold font-poppins mb-2">Follow Us</h3>
          <div className="flex gap-4">
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white p-2 rounded-full"
            >
              <FaFacebookF />
            </a>
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
              className="bg-pink-500 text-white p-2 rounded-full"
            >
              <FaInstagram />
            </a>
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-700 text-white p-2 rounded-full"
            >
              <FaLinkedinIn />
            </a>
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-600 text-white p-2 rounded-full"
            >
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>

      <div className="mt-8 border-t border-gray-400 pt-4 text-center text-sm text-white font-poppins mx-4 lg:mx-0 font-medium">
        © {new Date().getFullYear()} STEM Avishkar Private Limited. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;